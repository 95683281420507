import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, booleanAttribute, ContentChildren, ContentChild, Input, Output, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { TranslationKeys, SharedModule, PrimeTemplate } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { TimesCircleIcon } from 'primeng/icons';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-chip {
    display: inline-flex;
    align-items: center;
    background: ${dt('chip.background')};
    color: ${dt('chip.color')};
    border-radius: ${dt('chip.border.radius')};
    padding: ${dt('chip.padding.y')} ${dt('chip.padding.x')};
    gap: ${dt('chip.gap')};
}

.p-chip-icon {
    color: ${dt('chip.icon.color')};
    font-size: ${dt('chip.icon.font.size')};
    width: ${dt('chip.icon.size')};
    height: ${dt('chip.icon.size')};
}

.p-chip-image {
    border-radius: 50%;
    width: ${dt('chip.image.width')};
    height: ${dt('chip.image.height')};
    margin-left: calc(-1 * ${dt('chip.padding.y')});
}

.p-chip:has(.p-chip-remove-icon) {
    padding-inline-end: ${dt('chip.padding.y')};
}

.p-chip:has(.p-chip-image) {
    padding-top: calc(${dt('chip.padding.y')} / 2);
    padding-bottom: calc(${dt('chip.padding.y')} / 2);
}

.p-chip-remove-icon {
    cursor: pointer;
    font-size: ${dt('chip.remove.icon.font.size')};
    width: ${dt('chip.remove.icon.size')};
    height: ${dt('chip.remove.icon.size')};
    color: ${dt('chip.remove.icon.color')};
    border-radius: 50%;
    transition: outline-color ${dt('chip.transition.duration')}, box-shadow ${dt('chip.transition.duration')};
    outline-color: transparent;
}

.p-chip-remove-icon:focus-visible {
    box-shadow: ${dt('chip.remove.icon.focus.ring.shadow')};
    outline: ${dt('chip.remove.icon.focus.ring.width')} ${dt('chip.remove.icon.focus.ring.style')} ${dt('chip.remove.icon.focus.ring.color')};
    outline-offset: ${dt('chip.remove.icon.focus.ring.offset')};
}
`;
const classes = {
  root: 'p-chip p-component',
  image: 'p-chip-image',
  icon: 'p-chip-icon',
  label: 'p-chip-label',
  removeIcon: 'p-chip-remove-icon'
};
class ChipStyle extends BaseStyle {
  name = 'chip';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ChipStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ChipStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: ChipStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * Chip represents people using icons, labels and images.
 *
 * [Live Demo](https://www.primeng.org/chip)
 *
 * @module chipstyle
 *
 */
var ChipClasses;
(function (ChipClasses) {
  /**
   * Class name of the root element
   */
  ChipClasses["root"] = "p-chip";
  /**
   * Class name of the image element
   */
  ChipClasses["image"] = "p-chip-image";
  /**
   * Class name of the icon element
   */
  ChipClasses["icon"] = "p-chip-icon";
  /**
   * Class name of the label element
   */
  ChipClasses["label"] = "p-chip-label";
  /**
   * Class name of the remove icon element
   */
  ChipClasses["removeIcon"] = "p-chip-remove-icon";
})(ChipClasses || (ChipClasses = {}));

/**
 * Chip represents people using icons, labels and images.
 * @group Components
 */
class Chip extends BaseComponent {
  /**
   * Defines the text to display.
   * @group Props
   */
  label;
  /**
   * Defines the icon to display.
   * @group Props
   */
  icon;
  /**
   * Defines the image to display.
   * @group Props
   */
  image;
  /**
   * Alt attribute of the image.
   * @group Props
   */
  alt;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Whether to display a remove icon.
   * @group Props
   */
  removable = false;
  /**
   * Icon of the remove element.
   * @group Props
   */
  removeIcon;
  /**
   * Callback to invoke when a chip is removed.
   * @param {MouseEvent} event - Mouse event.
   * @group Emits
   */
  onRemove = new EventEmitter();
  /**
   * This event is triggered if an error occurs while loading an image file.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onImageError = new EventEmitter();
  visible = true;
  get removeAriaLabel() {
    return this.config.getTranslation(TranslationKeys.ARIA)['removeLabel'];
  }
  /**
   * Used to pass all properties of the chipProps to the Chip component.
   * @group Props
   */
  get chipProps() {
    return this._chipProps;
  }
  set chipProps(val) {
    this._chipProps = val;
    if (val && typeof val === 'object') {
      //@ts-ignore
      Object.entries(val).forEach(([k, v]) => this[`_${k}`] !== v && (this[`_${k}`] = v));
    }
  }
  _chipProps;
  _componentStyle = inject(ChipStyle);
  removeIconTemplate;
  templates;
  _removeIconTemplate;
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'removeicon':
          this._removeIconTemplate = item.template;
          break;
        default:
          this._removeIconTemplate = item.template;
          break;
      }
    });
  }
  ngOnChanges(simpleChanges) {
    super.ngOnChanges(simpleChanges);
    if (simpleChanges.chipProps && simpleChanges.chipProps.currentValue) {
      const {
        currentValue
      } = simpleChanges.chipProps;
      if (currentValue.label !== undefined) {
        this.label = currentValue.label;
      }
      if (currentValue.icon !== undefined) {
        this.icon = currentValue.icon;
      }
      if (currentValue.image !== undefined) {
        this.image = currentValue.image;
      }
      if (currentValue.alt !== undefined) {
        this.alt = currentValue.alt;
      }
      if (currentValue.style !== undefined) {
        this.style = currentValue.style;
      }
      if (currentValue.styleClass !== undefined) {
        this.styleClass = currentValue.styleClass;
      }
      if (currentValue.removable !== undefined) {
        this.removable = currentValue.removable;
      }
      if (currentValue.removeIcon !== undefined) {
        this.removeIcon = currentValue.removeIcon;
      }
    }
  }
  containerClass() {
    let classes = 'p-chip p-component';
    if (this.styleClass) {
      classes += ` ${this.styleClass}`;
    }
    return classes;
  }
  close(event) {
    this.visible = false;
    this.onRemove.emit(event);
  }
  onKeydown(event) {
    if (event.key === 'Enter' || event.key === 'Backspace') {
      this.close(event);
    }
  }
  imageError(event) {
    this.onImageError.emit(event);
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: Chip,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: Chip,
    isStandalone: true,
    selector: "p-chip",
    inputs: {
      label: "label",
      icon: "icon",
      image: "image",
      alt: "alt",
      style: "style",
      styleClass: "styleClass",
      removable: ["removable", "removable", booleanAttribute],
      removeIcon: "removeIcon",
      chipProps: "chipProps"
    },
    outputs: {
      onRemove: "onRemove",
      onImageError: "onImageError"
    },
    host: {
      properties: {
        "class": "containerClass()",
        "style": "style",
        "style.display": "!visible && \"none\"",
        "attr.data-pc-name": "'chip'",
        "attr.aria-label": "label",
        "attr.data-pc-section": "'root'"
      }
    },
    providers: [ChipStyle],
    queries: [{
      propertyName: "removeIconTemplate",
      first: true,
      predicate: ["removeicon"]
    }, {
      propertyName: "templates",
      predicate: PrimeTemplate
    }],
    usesInheritance: true,
    usesOnChanges: true,
    ngImport: i0,
    template: `
        <ng-content></ng-content>
        <img class="p-chip-image" [src]="image" *ngIf="image; else iconTemplate" (error)="imageError($event)" [alt]="alt" />
        <ng-template #iconTemplate><span *ngIf="icon" [class]="icon" [ngClass]="'p-chip-icon'" [attr.data-pc-section]="'icon'"></span></ng-template>
        <div class="p-chip-label" *ngIf="label" [attr.data-pc-section]="'label'">{{ label }}</div>
        <ng-container *ngIf="removable">
            <ng-container *ngIf="!removeIconTemplate && !_removeIconTemplate">
                <span
                    tabindex="0"
                    *ngIf="removeIcon"
                    [class]="removeIcon"
                    [ngClass]="'p-chip-remove-icon'"
                    [attr.data-pc-section]="'removeicon'"
                    (click)="close($event)"
                    (keydown)="onKeydown($event)"
                    [attr.aria-label]="removeAriaLabel"
                    role="button"
                ></span>
                <TimesCircleIcon tabindex="0" *ngIf="!removeIcon" [class]="'p-chip-remove-icon'" [attr.data-pc-section]="'removeicon'" (click)="close($event)" (keydown)="onKeydown($event)" [attr.aria-label]="removeAriaLabel" role="button" />
            </ng-container>
            <span *ngIf="removeIconTemplate || _removeIconTemplate" tabindex="0" [attr.data-pc-section]="'removeicon'" class="p-chip-remove-icon" (click)="close($event)" (keydown)="onKeydown($event)" [attr.aria-label]="removeAriaLabel" role="button">
                <ng-template *ngTemplateOutlet="removeIconTemplate || _removeIconTemplate"></ng-template>
            </span>
        </ng-container>
    `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i1.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "component",
      type: TimesCircleIcon,
      selector: "TimesCircleIcon"
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: Chip,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-chip',
      standalone: true,
      imports: [CommonModule, TimesCircleIcon, SharedModule],
      template: `
        <ng-content></ng-content>
        <img class="p-chip-image" [src]="image" *ngIf="image; else iconTemplate" (error)="imageError($event)" [alt]="alt" />
        <ng-template #iconTemplate><span *ngIf="icon" [class]="icon" [ngClass]="'p-chip-icon'" [attr.data-pc-section]="'icon'"></span></ng-template>
        <div class="p-chip-label" *ngIf="label" [attr.data-pc-section]="'label'">{{ label }}</div>
        <ng-container *ngIf="removable">
            <ng-container *ngIf="!removeIconTemplate && !_removeIconTemplate">
                <span
                    tabindex="0"
                    *ngIf="removeIcon"
                    [class]="removeIcon"
                    [ngClass]="'p-chip-remove-icon'"
                    [attr.data-pc-section]="'removeicon'"
                    (click)="close($event)"
                    (keydown)="onKeydown($event)"
                    [attr.aria-label]="removeAriaLabel"
                    role="button"
                ></span>
                <TimesCircleIcon tabindex="0" *ngIf="!removeIcon" [class]="'p-chip-remove-icon'" [attr.data-pc-section]="'removeicon'" (click)="close($event)" (keydown)="onKeydown($event)" [attr.aria-label]="removeAriaLabel" role="button" />
            </ng-container>
            <span *ngIf="removeIconTemplate || _removeIconTemplate" tabindex="0" [attr.data-pc-section]="'removeicon'" class="p-chip-remove-icon" (click)="close($event)" (keydown)="onKeydown($event)" [attr.aria-label]="removeAriaLabel" role="button">
                <ng-template *ngTemplateOutlet="removeIconTemplate || _removeIconTemplate"></ng-template>
            </span>
        </ng-container>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [ChipStyle],
      host: {
        '[class]': 'containerClass()',
        '[style]': 'style',
        '[style.display]': '!visible && "none"',
        '[attr.data-pc-name]': "'chip'",
        '[attr.aria-label]': 'label',
        '[attr.data-pc-section]': "'root'"
      }
    }]
  }],
  propDecorators: {
    label: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    image: [{
      type: Input
    }],
    alt: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    removable: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    removeIcon: [{
      type: Input
    }],
    onRemove: [{
      type: Output
    }],
    onImageError: [{
      type: Output
    }],
    chipProps: [{
      type: Input
    }],
    removeIconTemplate: [{
      type: ContentChild,
      args: ['removeicon', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  }
});
class ChipModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ChipModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ChipModule,
    imports: [Chip, SharedModule],
    exports: [Chip, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ChipModule,
    imports: [Chip, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: ChipModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [Chip, SharedModule],
      exports: [Chip, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { Chip, ChipClasses, ChipModule, ChipStyle };
